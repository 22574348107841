import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Let's build a Sankey diagram",
  "description": "Have you ever tried making a sankey diagram with d3+react, I can't seem to make it work for some reason.:/",
  "date": "2018-12-16T08:00:00.000Z",
  "published": "2018-12-16T08:00:00.000Z",
  "image": "./sankey.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <lite-youtube {...{
      "videoid": "SAmKimb8wFo",
      "videostartat": "0"
    }}></lite-youtube>
    <blockquote>
      <p parentName="blockquote">{`Have you ever tried making a sankey diagram with d3+react, I can't seem to make it work for some reason.:/`}</p>
      <p parentName="blockquote">{`~`}{`Emil`}</p>
    </blockquote>
    <p>{`No Emil, I have not. Let's give it a shot! Thanks for finding us a dataset that fits :)`}</p>
    <strong>Dataset: </strong>
    <a href={`./ugr-sankey-openspending.json`}>Download dataset 🗳 </a>
    <h2 {...{
      "id": "my-solution-"
    }}>{`My solution 👇`}</h2>
    <iframe {...{
      "src": "https://codesandbox.io/embed/m9vy7mr5k8",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "what-is-a-sankey-diagram"
    }}>{`What is a Sankey diagram?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Sankey_diagram"
      }}>{`Sankey diagrams`}</a>{` are flow diagrams. They're often used to show flows of money and other resources between different parts of an organization. Or between different organizations. Sankey originally designed them to show energy flows in factories.`}</p>
    <p>{`Vertical rectangles represent nodes in the flow, lines connecting the rectangles show how each node contributes to the inputs of the next node. Line thickness correlates to flow magnitude.`}</p>
    <p>{`One of the most famous Sankey diagrams in history is this visualization of Napoleon's invasion into Russia.`}</p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/2/29/Minard.png",
        "alt": null
      }}></img></p>
    <p>{`No I'm not quite sure how to read that either. But it's cool and it's old ✌️`}</p>
    <h2 {...{
      "id": "how-do-you-make-a-sankey-with-react-and-d3"
    }}>{`How do you make a sankey with React and D3?`}</h2>
    <p>{`Turns out building a Sankey diagram with React and D3 isn't terribly difficult. A D3 extension library called `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-sankey"
      }}>{`d3-sankey`}</a>{` provides a generator for them. Your job is to fill it with data, then render.`}</p>
    <p>{`The dataset Emil found for us was specifically designed for Sankey diagrams so that was awesome. Thanks Emil. 🙏🏻`}</p>
    <p>{`I don't know what `}<em parentName="p">{`our`}</em>{` data represents, but you gotta wrangle yours into `}<inlineCode parentName="p">{`nodes`}</inlineCode>{` and `}<inlineCode parentName="p">{`links`}</inlineCode>{`.`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`nodes`}</inlineCode>{` are an array of representative keys, names in our case`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`links`}</inlineCode>{` are an array of objects mapping a `}<inlineCode parentName="li">{`source`}</inlineCode>{` index to a `}<inlineCode parentName="li">{`target`}</inlineCode>{` index with a numeric `}<inlineCode parentName="li">{`value`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "nodes": [
    {
      "name": "Universidad de Granada"
    },
    {
      "name": "De Comunidades Autónomas"
    },
   //...
  ],
  "links": [
    {
      "source": 19,
      "target": 26,
      "value": 1150000
    },
    {
      "source": 0,
      "target": 19,
      "value": 283175993
    },
    //...
}
`}</code></pre>
    <h3 {...{
      "id": "turn-data-into-a-sankey-layout"
    }}>{`Turn data into a Sankey layout`}</h3>
    <p>{`We can keep things simple with a functional component that calculates the Sankey layout on the fly with every render. We'll need some color stuff too. That was actually the hardest, lol.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { sankey, sankeyLinkHorizontal } from "d3-sankey";
//...

const MysteriousSankey = ({ data, width, height }) => {
  const { nodes, links } = sankey()
    .nodeWidth(15)
    .nodePadding(10)
    .extent([[1, 1], [width - 1, height - 5]])(data);
  const color = chroma.scale("Set3").classes(nodes.length);
  const colorScale = d3
    .scaleLinear()
    .domain([0, nodes.length])
    .range([0, 1]);
`}</code></pre>
    <p>{`It's called `}<inlineCode parentName="p">{`MysteriousSankey`}</inlineCode>{` because I don't know what our dataset represents. Takes a width, a height, and a data prop.`}</p>
    <p>{`We get the `}<inlineCode parentName="p">{`sankey`}</inlineCode>{` generator from `}<inlineCode parentName="p">{`d3-sankey`}</inlineCode>{`, initialize a new generator with `}<inlineCode parentName="p">{`sankey()`}</inlineCode>{`, define a width for our nodes and give them some vertical padding. Extent defines the size of our diagram with 2 coordinates: the top left and bottom right corner.`}</p>
    <p>{`Colors are a little trickier. We use `}<inlineCode parentName="p">{`chroma`}</inlineCode>{` to define a color scale based on the predefined `}<inlineCode parentName="p">{`Set3`}</inlineCode>{` brewer category. We split it up into `}<inlineCode parentName="p">{`nodes.length`}</inlineCode>{` worth of colors - one for each node. But this expects inputs like `}<inlineCode parentName="p">{`0.01`}</inlineCode>{`, `}<inlineCode parentName="p">{`0.1`}</inlineCode>{` etc.`}</p>
    <p>{`To make that easier we define a `}<inlineCode parentName="p">{`colorScale`}</inlineCode>{` as well. It takes indexes of our nodes and translates them into those 0 to 1 numbers. Feed that into the `}<inlineCode parentName="p">{`color`}</inlineCode>{` thingy and it returns a color for each node.`}</p>
    <h3 {...{
      "id": "render-your-sankey"
    }}>{`Render your Sankey`}</h3>
    <p>{`A good approach to render your Sankey diagram is using two components:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`<SankeyNode>`}</inlineCode>{` for each node`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`<SankeyLink>`}</inlineCode>{` for each link between them`}</li>
    </ol>
    <p>{`You use them in two loops in the main `}<inlineCode parentName="p">{`<MysteriousSankey>`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <g style={{ mixBlendMode: "multiply" }}>
    {nodes.map((node, i) => (
      <SankeyNode
        {...node}
        color={color(colorScale(i)).hex()}
        key={node.name}
      />
    ))}
    {links.map((link, i) => (
      <SankeyLink
        link={link}
        color={color(colorScale(link.source.index)).hex()}
      />
    ))}
  </g>
)
`}</code></pre>
    <p>{`Here you can see a case of inconsistent API design. `}<inlineCode parentName="p">{`SankeyNode`}</inlineCode>{` gets node data splatted into props, `}<inlineCode parentName="p">{`SankeyLink`}</inlineCode>{` prefers a single prop for all the `}<inlineCode parentName="p">{`link`}</inlineCode>{` info. There's a reason for that and you might want to keep to the same approach in both anyway.`}</p>
    <p>{`Both also get a `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop with the messiness of translating a node index into a `}<inlineCode parentName="p">{`[0, 1]`}</inlineCode>{` number passed into the chroma color scale, translated into a hex string. Mess.`}</p>
    <h3 {...{
      "id": "sankeynode"
    }}>{`<`}{`SankeyNode>`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const SankeyNode = ({ name, x0, x1, y0, y1, color }) => (
  <rect x={x0} y={y0} width={x1 - x0} height={y1 - y0} fill={color}>
    <title>{name}</title>
  </rect>
)
`}</code></pre>
    <p><inlineCode parentName="p">{`SankeyNode`}</inlineCode>{`s are rectangles with a title. We take top left and bottom right coordinates from the sankey generator and feed them into rect SVG elements. Color comes form the color prop.`}</p>
    <h3 {...{
      "id": "sankeylink"
    }}>{`<`}{`SankeyLink>`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const SankeyLink = ({ link, color }) => (
  <path
    d={sankeyLinkHorizontal()(link)}
    style={{
      fill: "none",
      strokeOpacity: ".3",
      stroke: color,
      strokeWidth: Math.max(1, link.width),
    }}
  />
)
`}</code></pre>
    <p><inlineCode parentName="p">{`SankeyLink`}</inlineCode>{`s are paths. We initialze a `}<inlineCode parentName="p">{`sankeyLinkHorizontal`}</inlineCode>{` path generator instance, feed it `}<inlineCode parentName="p">{`link`}</inlineCode>{` info and that creates the path shape for us. This is why it was easier to get everything in a single `}<inlineCode parentName="p">{`link`}</inlineCode>{` prop. No idea which arguments the generator actually uses.`}</p>
    <p>{`Styling is tricky too.`}</p>
    <p>{`Sankey links are lines. They don't look like lines, but that's what they are. You want to make sure `}<inlineCode parentName="p">{`fill`}</inlineCode>{` is set to nothing, and use `}<inlineCode parentName="p">{`strokeWidth`}</inlineCode>{` to get that nice volume going.`}</p>
    <p>{`The rest is just colors and opacities to make it look prettier.`}</p>
    <p>{`A sankey diagram comes out 👇`}</p>
    <p><img parentName="p" {...{
        "src": "./sankey.png",
        "alt": null
      }}></img></p>
    <p>{`You can make it betterer with some interaction on the nodes or even links. They're components so the world is your oyster. Anything you can do with components, you can do with these.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      